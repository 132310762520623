<message-filters (onFilter)="onFilter($event)" (onExport)="onExportMessages.emit($event)" [clients]="clients | async"
  [defaultFilters]="defaultFilters">
</message-filters>

<div [hidden]="!loaded">

  <mat-card class="u-center-text" *ngIf="!messages?.length">
    <h4>Nessun messaggio trovato</h4>
  </mat-card>

  <div [hidden]="!messages?.length">
    <mat-card class="u-center-text">
      <action-button *ngIf="selectedMessages?.length > 0" (onCancelMessages)="cancelMessages($event)"
        (onRescheduleMessages)="rescheduleMessages($event)" (onSendMessages)="sendMessages($event)"></action-button>
      <!-- <mat-card-title>Storico Messaggi (LOG)</mat-card-title> -->

      <table mat-table matSort [dataSource]="messages">
        <ng-container matColumnDef="selection">
          <th mat-header-cell *matHeaderCellDef>
            <div class="u-flex-column">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="selectAllMessages($event.checked)"
                color="primary"></mat-checkbox>
            </div>
          </th>
          <td mat-cell *matCellDef="let message">
            <ng-container *ngIf="message.selectable">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="selectMessage(message, $event.checked)"
                [checked]="selectedMessages.includes(message.id)" color="primary"></mat-checkbox>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
          <td mat-cell *matCellDef="let message">{{ message.id }}</td>
        </ng-container>

        <ng-container matColumnDef="external_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="external_id">ID</th>
          <td mat-cell *matCellDef="let message">{{ message.externalId }}</td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="created_at">
            Presa in Carico
          </th>
          <td mat-cell *matCellDef="let message">
            {{ message.createdAt | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="process_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="process_date">
            Invio
          </th>
          <td mat-cell *matCellDef="let message">
            {{ message.processDate | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="telefono">
          <th mat-header-cell *matHeaderCellDef>Telefono</th>
          <td mat-cell *matCellDef="let message">{{ message.phone }}</td>
        </ng-container>

        <ng-container matColumnDef="stato">
          <th mat-header-cell *matHeaderCellDef>Stato</th>
          <td mat-cell *matCellDef="let message">
            {{ message.messageStatus | status }}
            <div class="small" *ngIf="message.messageStatus === failed && !!message.responseMessage">
              {{message.responseMessage}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="nomeCliente">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell *matCellDef="let message">{{ message.nomeCliente }}</td>
        </ng-container>

        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef>Provider</th>
          <td mat-cell *matCellDef="let message">
            {{ message.providerTemplate | templateProvider }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invioMassivo">
          <th mat-header-cell *matHeaderCellDef>Invio Massivo</th>
          <td mat-cell *matCellDef="let message">
            <mat-icon *ngIf="message.nomeTemplate === 'Invio Massivo'">
              check
            </mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="sourceType">
          <th mat-header-cell *matHeaderCellDef>Tipologia</th>
          <td mat-cell *matCellDef="let message">
            {{message.sourceType | sourceType}}
            <div class="small" *ngIf="message.sourceType === 'SEQUENCE'">
              Template: {{ message?.nomeTemplate }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let message">
            <ng-container *ngIf="message.messageStatus === 'PENDING'">
              <button mat-icon-button [mat-menu-trigger-for]="actionMenu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
                <ng-container *ngIf="message.templateId">
                  <button mat-menu-item *ngIf="
                    message.messageStatus === pending ||
                    message.messageStatus === cancelled_status
                  " (click)="rescheduleMessage(message)">
                    Cambia data invio
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="cancelSequence(message)">
                    Annulla sequenza
                  </button>
                </ng-container>

                <ng-container *ngIf="!message.templateId">
                  <button mat-menu-item (click)="cancelMessage(message)">
                    Annulla messaggio
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="total | async"
        showFirstLastButtons>
      </mat-paginator>

      <action-button *ngIf="selectedMessages?.length > 0" (onCancelMessages)="cancelMessages($event)"
        (onRescheduleMessages)="rescheduleMessages($event)" (onSendMessages)="sendMessages($event)"></action-button>
    </mat-card>
  </div>
</div>