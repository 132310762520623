<mat-expansion-panel hideToggle [expanded]="true">
  <mat-expansion-panel-header> Filtra </mat-expansion-panel-header>

  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <!-- <mat-form-field floatLabel="always" class="form-text">
        <mat-label>Ricerca libera</mat-label>
        <input type="text" matInput formControlName="search" />
      </mat-form-field> -->

      <!-- <mat-form-field *showFor="['admin', 'partner']" floatLabel="always" class="form-text">
        <mat-label>Cliente</mat-label>
        <mat-select formControlName="clientId">
          <mat-option *ngFor="let client of clients | async" [value]="client?.id">
            {{ client?.nome }}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field *showFor="['admin', 'partner']" class="form-text">
        <mat-label>Cliente</mat-label>
        <input type="text" matInput formControlName="client" [matAutocomplete]="clientAutocomplete">
        <mat-autocomplete autoActiveFirstOption #clientAutocomplete="matAutocomplete" [displayWith]="displayClient">
          <mat-option *ngFor="let client of filteredClients | async" [value]="client">
            {{client.nome}}
          </mat-option>
        </mat-autocomplete>
        <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="filtersForm.value.client"
          (click)="filtersForm.patchValue({client: ''})">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="form-text">
        <mat-label>Stato</mat-label>
        <mat-select formControlName="statuses" multiple>
          <mat-select-trigger>
            <span *ngIf="
                filtersForm.value.statuses?.length === messageStatuses.length
              ">Tutti</span>
            <span *ngIf="
                filtersForm.value.statuses?.length < messageStatuses.length
              ">
              {{
              (filtersForm.value.statuses
              ? filtersForm.value.statuses[0]
              : ""
              ) | status
              }}
              <span *ngIf="filtersForm.value.statuses?.length > 1" class="additional-selection">
                (+{{ filtersForm.value.statuses.length - 1 }}
                {{
                filtersForm.value.statuses?.length === 2 ? "altro" : "altri"
                }})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let status of messageStatuses" [value]="status">
            {{ status | status }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="form-text">
        <mat-label>Telefono</mat-label>
        <input type="text" matInput formControlName="phone" />
        <mat-hint>Anche numero parziale</mat-hint>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="form-text">
        <mat-label>ID</mat-label>
        <input type="text" matInput formControlName="externalId" />
        <mat-hint>Anche valore parziale</mat-hint>
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>Invio Massivo</mat-label>
        <mat-select floatLabel="always" formControlName="invioMassivo">
          <mat-option [value]="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field> -->

    </div>

    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="form-text">
        <mat-label>Tipologia</mat-label>
        <mat-select formControlName="types" multiple>
          <mat-select-trigger>
            <span *ngIf="
                filtersForm.value.types?.length === sourceTypes.length
              ">Tutti</span>
            <span *ngIf="
                filtersForm.value.types?.length < sourceTypes.length
              ">
              {{
              (filtersForm.value.types
              ? filtersForm.value.types[0]
              : ""
              ) | sourceType
              }}
              <span *ngIf="filtersForm.value.types?.length > 1" class="additional-selection">
                (+{{ filtersForm.value.types.length - 1 }}
                {{
                filtersForm.value.types?.length === 2 ? "altro" : "altri"
                }})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let sourceType of sourceTypes" [value]="sourceType">
            {{ sourceType | sourceType }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Motivo fallimento</mat-label>
        <mat-select formControlName="failReason">
          <mat-option [value]="">--- Tutti ---</mat-option>
          <mat-option *ngFor="let reason of failReasons" [value]="reason">
            {{ reason }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Data di presa in carico inizio"
          formControlName="createdAtFrom" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1 color="primary">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Data di presa in carico fine"
          formControlName="createdAtTo" />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker2 color="primary">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput [ngxMatDatetimePicker]="picker3" placeholder="Data di invio inizio"
          formControlName="sentAtFrom" />
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker3 color="primary">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput [ngxMatDatetimePicker]="picker4" placeholder="Data di invio fine" formControlName="sentAtTo" />
        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker4 color="primary">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>

    <span class="u-flex-full">
      <span>
        <button mat-raised-button color="primary" (click)="applyFilters()">
          Ricerca
        </button>
        <button mat-icon-button [disabled]="filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
      <button mat-raised-button (click)="exportMessages()">
        Esporta
      </button>
    </span>
  </form>
</mat-expansion-panel>