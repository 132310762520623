<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Gestione Webhooks </span><span *ngIf="user"> per {{user.name}}</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="hooksForm" novalidate>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Webhook Partner per avviso disconnessione" formControlName="statusHook" type="url" />
      <mat-hint align="end" matTooltip="URL che verr&agrave; chiamato alla disconnesione di un cliente">
        <mat-icon>help</mat-icon>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="u-full-width">
      <input matInput placeholder="Webhook Messaggio inviato" formControlName="sentMessageHook" type="url" />
      <mat-hint align="end"
        matTooltip="URL che verr&agrave; chiamato quando un messaggio di un qualsiasi cliente viene inviato">
        <mat-icon>help</mat-icon>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="u-full-width">
      <input matInput placeholder="Webhook Messaggio ricevuto" formControlName="recivedMessageHook" type="url" />
      <mat-hint align="end"
        matTooltip="URL che verr&agrave; chiamato quando un messaggio di un qualsiasi cliente viene ricevuto">
        <mat-icon>help</mat-icon>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="u-full-width">
      <input matInput placeholder="Webhook Messaggio fallito" formControlName="failedMessageHook" type="url" />
      <mat-hint align="end" matTooltip="URL che verr&agrave; chiamato quando un messaggio fallisce">
        <mat-icon>help</mat-icon>
      </mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="hooksForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="hooksForm.pristine || !hooksForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>