import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from './auth.reducers';
import * as fromAutomation from './automation.reducer';
import * as fromClient from './client.reducer';
import * as fromIntent from './intent.reducer';
import * as fromLibrary from './library.reducer';
import * as fromMessage from './message.reducer';
import * as fromSequence from './sequence.reducer';
import * as fromSession from './session.reducer';
import * as fromSheets from './sheets.reducer';
import * as fromSidebar from './sidebar.reducer';
import * as fromTemplate from './template.reducer';
import * as fromUser from './user.reducer';
import * as fromMailUsers from './mail-users.reducer';
import * as fromBlacklist from './blacklist.reducer';
import * as fromTemplateMessage from './templates-messages.reducer';


export interface AppState {
  sidebar: fromSidebar.SidebarState;
  router: fromRouter.RouterReducerState<any>;
  client: fromClient.ClientState;
  template: fromTemplate.TemplateState;
  sequence: fromSequence.SequenceState;
  templatesMessage: fromTemplateMessage.TemplatesMessageState;
  message: fromMessage.MessageState;
  session: fromSession.SessionState;
  auth: fromAuth.AuthState;
  user: fromUser.UserState;
  sheets: fromSheets.SheetsState;
  intent: fromIntent.IntentState;
  automation: fromAutomation.AutomationState;
  library: fromLibrary.LibraryState;
  mailUsers: fromMailUsers.MailUsersState;
  blacklist: fromBlacklist.BlacklistState;
}

export const reducers: ActionReducerMap<AppState> = {
  sidebar: fromSidebar.reducer,
  router: fromRouter.routerReducer,
  client: fromClient.reducer,
  template: fromTemplate.reducer,
  templatesMessage: fromTemplateMessage.reducer,
  sequence: fromSequence.reducer,
  message: fromMessage.reducer,
  session: fromSession.reducer,
  auth: fromAuth.reducer,
  user: fromUser.reducer,
  sheets: fromSheets.reducer,
  intent: fromIntent.reducer,
  automation: fromAutomation.reducer,
  library: fromLibrary.reducer,
  mailUsers: fromMailUsers.reducer,
  blacklist: fromBlacklist.reducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [{ auth: ["token"] }],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, fromAuth.clearState];

export const selectRouter = createFeatureSelector<
  AppState,
  fromRouter.RouterReducerState<any>
>("router");

export const selectClientState = createFeatureSelector<fromClient.ClientState>(
  "client"
);

export const selectTemplateState = createFeatureSelector<fromTemplate.TemplateState>(
  "template"
);

export const selectSequenceState = createFeatureSelector<fromSequence.SequenceState>(
  "sequence"
);

export const selectTemplatesMessage = createFeatureSelector<fromTemplateMessage.TemplatesMessageState>(
  "templatesMessage"
);

export const selectMessageState = createFeatureSelector<fromMessage.MessageState>(
  "message"
);

export const selectSessionState = createFeatureSelector<fromSession.SessionState>(
  "session"
)

export const selectSidebarState = createFeatureSelector<fromSidebar.SidebarState>(
  "sidebar"
);
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  "auth"
);
export const selectUserState = createFeatureSelector<fromUser.UserState>(
  "user"
);

export const selectSheetsState = createFeatureSelector<fromSheets.SheetsState>(
  "sheets"
);

export const selectIntentState = createFeatureSelector<fromIntent.IntentState>(
  "intent"
);

export const selectAutomationState = createFeatureSelector<fromAutomation.AutomationState>(
  "automation"
);

export const selectLibraryState = createFeatureSelector<fromLibrary.LibraryState>(
  "library"
);

export const selectMailUsersState = createFeatureSelector<fromMailUsers.MailUsersState>(
  "mailUsers"
);

export const selectBlacklistState = createFeatureSelector<fromBlacklist.BlacklistState>(
  "blacklist"
);

const {
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam("id");
export const selectStatus = selectQueryParam("status");
