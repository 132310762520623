import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TemplatesMessage } from 'src/app/commons/models/templates-messages.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { LaravelTemplatesMessageService } from 'src/app/commons/services/backend/laravel-templates-message.service';
import {
  TemplateMessageEditComponent,
} from 'src/app/modules/home/client/templates-message/template-message-edit/template-message-edit.component';

import * as RouterActions from '../actions/router.actions';
import * as TemplatesMessageActions from '../actions/templates-messages.actions';
import { AppState } from '../reducers';
import * as ClientSelectors from '../selectors/client.selectors';
import * as TemplatesMessageSelectors from '../selectors/templates-messages.selectors';
import *  as ClientActions from '../actions/client.actions';
import *  as SequenceActions from '../actions/sequence.actions';
@Injectable()
export class TemplatesMessageEffects {

  error$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.saveTemplateMessageFailed, TemplatesMessageActions.cloneTemplateMessageFailed, TemplatesMessageActions.deleteTemplateMessageFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore:', error);
        }
      })
    )
  }, { dispatch: false }
  );

  onLoadClientCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.loadClientCompleted, SequenceActions.loadSequenceCompleted),
      map(() => TemplatesMessageActions.loadTemplatesMessage({ page: 0, perPage: 0, filters: {} }))
    )
  })

  loadTemplatesMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.loadTemplatesMessage),
      concatLatestFrom(() => this.store$.select(TemplatesMessageSelectors.getSessionId)),
      switchMap(([{ page, perPage, order, direction, filters }, sessionId]) => {
        return this.templatesMessageService.list(sessionId, page, perPage, order, direction, filters)
          .pipe(
            map(result => {
              console.log('Result from service:', result);
              return TemplatesMessageActions.loadTemplatesMessageCompleted({ templatesMessage: result.data, currentPage: page, total: result.total, perPage, order, direction, filters })
            }
            ),
            catchError(error => {
              return of(TemplatesMessageActions.loadTemplatesMessageFailed({ error }))
            })
          )
      })
    )
  }
  );

  retrieveTemplateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.retrieveTemplateMessage),
      switchMap(({ templateMessage }) => {
        return this.templatesMessageService.retrive(templateMessage.id)
          .pipe(
            map(result =>
              TemplatesMessageActions.retrieveTemplateMessageCompleted({ templateMessage: result })
            ),
            catchError(error => {
              return of(TemplatesMessageActions.retrieveTemplateMessageFailed({ error }))
            })
          )
      })
    )
  });


  loadTemplateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.loadTemplateMessage),
      switchMap(({ id }) => {
        return this.templatesMessageService.show(id)
          .pipe(
            map(result =>
              TemplatesMessageActions.loadTemplateMessageCompleted({ templateMessage: result })
            ),
            catchError(error => {
              return of(TemplatesMessageActions.loadTemplateMessageFailed({ error }))
            })
          )
      })
    )
  }
  );


  changePage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.changePage),
      concatLatestFrom(() => this.store$.select(TemplatesMessageSelectors.getTemplatesMessageTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, }]) => TemplatesMessageActions.loadTemplatesMessage({ page, perPage: size, order, direction, filters }))
    )
  }
  );

  changeSort$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.changeSort),
      concatLatestFrom(() => this.store$.select(TemplatesMessageSelectors.getTemplatesMessageTableState)),
      map(([action, { sessionId, total, currentPage, perPage, direction, order, filters }]) => TemplatesMessageActions.loadTemplatesMessage({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters }))
    )
  }
  );

  changeFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.changeFilters),
      concatLatestFrom(() => this.store$.select(TemplatesMessageSelectors.getTemplatesMessageTableState)),
      map(([{ filters }, { sessionId, total, currentPage, perPage, direction, order }]) => TemplatesMessageActions.loadTemplatesMessage({ page: currentPage, perPage, order, direction, filters }))
    )
  }
  );


  editTemplateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.editTemplateMessage),
      map(({ templateMessage }) => {
        console.log(templateMessage + ' editTemplateMessage effects'); // Log in effect
        return templateMessage ? new TemplatesMessage(templateMessage) : null;
      }),
      concatLatestFrom(() => this.store$.select(TemplatesMessageSelectors.getSessionId)),
      map(([templateMessage, sessionId]) => {
        const dialogData = { templateMessage, sessionId };
        console.log(dialogData, ' dialog data'); // Log data before opening dialog
        let dialogRef = this.dialog.open(TemplateMessageEditComponent, {
          data: dialogData,
          width: '80%'
        });
        return TemplatesMessageActions.templateMessageDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });


  saveTemplateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.saveTemplateMessage),
      mergeMap(({ templateMessage }) =>
        this.templatesMessageService.upsert(templateMessage)
          .pipe(
            map(result =>
              TemplatesMessageActions.saveTemplateMessageCompleted({ templateMessage: result })
            ),
            catchError(error => of(TemplatesMessageActions.saveTemplateMessageFailed({ error })))
          )
      )
    )
  }
  );

  onSaveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.saveTemplateMessageCompleted),
      map(action => action.templateMessage),
      tap(templateMessage => this.alertService.showConfirmMessage(`Modello ${templateMessage.name} salvato con successo`)),
      map(() => TemplatesMessageActions.closeTemplateMessageDialog())
    )
  }
  );

  cloneTemplateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.cloneTemplateMessage),
      filter(({ templateMessage }) => !!templateMessage),
      switchMap(({ templateMessage }) =>
        this.alertService.showConfirmDialog('Conferma duplicazione', `Sei sicuro di voler duplicare il modello ${templateMessage.name}?`)
          .pipe(
            filter(confirm => !!confirm),
            switchMap(() => this.templatesMessageService.clone(templateMessage.id).pipe(
              map((cloned) => TemplatesMessageActions.cloneTemplateMessageCompleted({ templateMessage: cloned })),
              catchError(error => of(TemplatesMessageActions.cloneTemplateMessageFailed({ error })))
            ))
          )
      )
    )
  }
  );

  sendTemplateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.sendTemplateMessage),
      withLatestFrom(
        this.store$.select(ClientSelectors.getSessionWaNumId),
        this.store$.select(ClientSelectors.getSessionsWaToken),
      ),
      switchMap(([action, waNumId, waToken]) =>
        this.alertService.showConfirmDialog(
          'Conferma invio modello',
          `Sei sicuro di voler inviare il modello ${action.templateMessage.name}?`
        ).pipe(
          mergeMap((confirm) =>
            confirm
              ? this.templatesMessageService.send(action.templateMessage.id, waNumId, waToken).pipe(
                map(() => TemplatesMessageActions.sendTemplateMessageCompleted({ templateMessage: action.templateMessage })),
                catchError((error) => of(TemplatesMessageActions.sendTemplateMessageFailed({ error })))
              )
              : of(TemplatesMessageActions.sendTemplateMessageCancelled())
          )
        )
      )
    )
  }
  );


  deleteTemplateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.deleteTemplateMessage),
      switchMap(({ templateMessage }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare il modello ${templateMessage.name}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.templatesMessageService.delete(templateMessage.id)
                  .pipe(
                    map(() => TemplatesMessageActions.deleteTemplateMessageCompleted({ templateMessage })),
                    catchError(error => of(TemplatesMessageActions.deleteTemplateMessageFailed({ error })))
                  )

                : of(TemplatesMessageActions.deleteTemplateMessageCancelled());
            })
          )
      )
    )
  }
  );

  onDeleteCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.deleteTemplateMessageCompleted),
      tap(({ templateMessage }) => this.alertService.showConfirmMessage(`Modello ${templateMessage.name} eliminato con successo`)),
      map(() => TemplatesMessageActions.closeTemplateMessageDialog()),
    )
  }
  );

  // backToClientAfterDelete$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(TemplatesMessageActions.deleteSequenceCompleted),
  //     filter(({ sequence }) => !!(sequence.session?.client_id)),
  //     map(({ sequence }) => sequence.session?.client_id),
  //     map(clientId => TemplatesMessageActions.backToClient({ clientId }))
  //   )
  // }
  // )

  closeDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.closeTemplateMessageDialog),
      concatLatestFrom(() => this.store$.select(TemplatesMessageSelectors.getTemplatesMessageDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    )
  }, { dispatch: false }
  );


  showErrrorAfterSaveOrDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType( TemplatesMessageActions.sendTemplateMessageFailed,
        TemplatesMessageActions.retrieveTemplateMessageFailed),
        tap(({ error }) => {
            let detailsStr = JSON.parse(error.error?.details);
            this.alertService.showErrorMessage('Errore:', detailsStr.error.error_user_msg);
        })
    )
  } , { dispatch: false }
  );

  reloadAfterSaveOrDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.saveTemplateMessageCompleted, TemplatesMessageActions.deleteTemplateMessageCompleted,
        TemplatesMessageActions.cloneTemplateMessageCompleted, TemplatesMessageActions.sendTemplateMessageCompleted,
        TemplatesMessageActions.retrieveTemplateMessageCompleted),
      concatLatestFrom(() => this.store$.select(TemplatesMessageSelectors.getTemplatesMessageTableState)),
      map(([_, { currentPage, perPage, direction, order, filters }]) => TemplatesMessageActions.loadTemplatesMessage({ page: currentPage, perPage, order, direction, filters })),
    )
  }
  );

  backToClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplatesMessageActions.backToClient),
      map(({ clientId }) => RouterActions.routerGo({
        path: ['/client/', clientId]
      }))
    )
  }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private templatesMessageService: LaravelTemplatesMessageService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
