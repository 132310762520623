import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'templatesMessageCategoryType'
})
export class TemplatesMessageCategoryTypePipe implements PipeTransform {

  private readonly categoryTranslations: { [key: string]: string } = {
    MARKETING: 'Marketing',
    UTILITY: 'Utilità',
    AUTHENTICATION: 'Autenticazione'
  };

  transform(value: string): string {
    return this.categoryTranslations[value] || value;
  }

}
