import { Action, createReducer, on } from '@ngrx/store';
import { ClientDTO } from 'src/app/commons/models/client.model';

import * as ClientActions from '../actions/client.actions';
import { ClientFilters } from './../../commons/models/client.model';

export interface ClientState {
  list: ClientDTO[],
  includes: string[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ClientFilters,
  dialogId: string,
  client: ClientDTO,
  clientId: number,
  socketClients: ClientDTO[]
};

const initialState: ClientState = {
  list: [],
  includes: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  client: null,
  clientId: null,
  socketClients: []
};

const clientReducer = createReducer(
  initialState,
  on(ClientActions.loadClientsCompleted, (state, { clients, currentPage, total, perPage, order, direction, filters, includes }) => {
    const socketClients = clients.filter(client => client.sessions.map(session => session.tipologia).includes('socket'));
    return {
      ...state,
      list: clients,
      currentPage,
      total,
      perPage,
      order,
      direction,
      filters,
      includes,
      socketClients
    };
  }),
  on(ClientActions.loadClientCompleted, (state, { client }) => {
    return { ...state, client };
  }),
  on(ClientActions.saveClientCompleted, (state, { client }) => {
    return { ...state, client };
  }),
  on(ClientActions.selectClient, (state, { client }) => {
    return { ...state, client: client };
  }),
  on(ClientActions.clientDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ClientActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ClientActions.selectClientId, (state, { id }) => {
    return { ...state, clientId: id }
  })
);

export function reducer(state: ClientState | undefined, action: Action) {
  return clientReducer(state, action);
}
