import { createAction, props } from '@ngrx/store';

import { Client, ClientDTO, ClientFilters } from '../../commons/models/client.model';
import { Session } from '../../commons/models/session.model';

export const selectClient = createAction('[Client] Select Client', props<{ client: ClientDTO }>());

export const loadClients = createAction('[Clients] Load clients', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: ClientFilters, includes?: string[] }>());
export const loadClientsCompleted = createAction('[Clients] Load clients Completed', props<{ clients: ClientDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ClientFilters, includes?: string[] }>());
export const loadClientsFailed = createAction('[Clients] Load clients Failed', props<{ error: any }>());

export const loadClient = createAction('[Clients] Load client', props<{ id: number }>());
export const loadClientCompleted = createAction('[Clients] Load client Completed', props<{ client: ClientDTO }>());
export const loadClientFailed = createAction('[Clients] Load client Failed', props<{ error: any }>());

export const changePage = createAction('[Clients] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Clients] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Clients] Change filters', props<{ filters: ClientFilters }>());

export const editClient = createAction('[Clients] Edit client', props<{ client: Client }>());
export const clientDialogOpened = createAction('[Clients] Detail dialog opened', props<{ dialogId: string }>());
export const closeClientDialog = createAction('[Clients] Close detail dialog');

export const saveClient = createAction('[Clients] Save client', props<{ client: Client, session?: Session }>());
export const saveClientCompleted = createAction('[Clients] Save client completed', props<{ client: ClientDTO }>());
export const saveClientFailed = createAction('[Clients] Save client failed', props<{ error: any }>());

export const deleteClient = createAction('[Clients] Delete client', props<{ client: Client }>());
export const deleteClientCompleted = createAction('[Clients] Delete client completed', props<{ client: ClientDTO }>());
export const deleteClientCancelled = createAction('[Clients] Delete client cancelled');
export const deleteClientFailed = createAction('[Clients] Delete client failed', props<{ error: any }>());

export const selectClientId = createAction('[Client] selectClientId', props<{ id: number }>());

export const setRecivedMessagesHook = createAction('[Client] Set recived messages hook', props<{ id: number, hook: string }>());
export const setRecivedMessagesHookCompleted = createAction('[Client] Set recived messages hook completed', props<{ client: ClientDTO }>());
export const setRecivedMessagesHookFailed = createAction('[Client] Set recived messages hook failed', props<{ error: any }>());

export const setSentMessagesHook = createAction('[Client] Set sent messages hook', props<{ id: number, hook: string }>());
export const setSentMessagesHookCompleted = createAction('[Client] Set sent messages hook completed', props<{ client: ClientDTO }>());
export const setSentMessagesHookFailed = createAction('[Client] Set sent messages hook failed', props<{ error: any }>());
