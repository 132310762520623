import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { ClientDTO, ClientFilters } from '../../models/client.model';
import { SessionDTO } from '../../models/session.model';


@Injectable({
  providedIn: "root"
})
export class LaravelClientService {

  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/clients",
      store: environment.baseUrl + "/api/client",
      show: environment.baseUrl + "/api/client",
      update: environment.baseUrl + "/api/client",
      destroy: environment.baseUrl + "/api/client",
      setRecivedMessagesHook: environment.baseUrl + "/api/client/recived_messages_hook",
      setSentMessagesHook: environment.baseUrl + "/api/client/sent_messages_hook"
    };
  }

  public list(page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filters?: ClientFilters,
    includes?: string[]
  ): Observable<ListResultDTO<ClientDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;
      if (filters.ids) params["ids[]"] = filters.ids;
    }
    if (includes) params["includes[]"] = includes;
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<ClientDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<ClientDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public upsert(client: ClientDTO, session?: SessionDTO): Observable<ClientDTO> {
    if (client.id) {
      return this.httpClient.put<ClientDTO>(this.ROUTES.store, { client, session });
    } else {
      return this.httpClient.post<ClientDTO>(this.ROUTES.store, { client, session });
    }
  }

  public show(id: number): Observable<ClientDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<ClientDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public delete(id: number): Observable<ClientDTO> {
    let params = { id: "" + id };
    return this.httpClient.delete<ClientDTO>(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public setRecivedMessagesHook(id: number, hook: string): Observable<ClientDTO> {
    return this.httpClient.post<ClientDTO>(this.ROUTES.setRecivedMessagesHook, { id, hook });
  }

  public setSentMessagesHook(id: number, hook: string): Observable<ClientDTO> {
    return this.httpClient.post<ClientDTO>(this.ROUTES.setSentMessagesHook, { id, hook });
  }
}
