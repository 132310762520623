import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'templatesMessageStatusType'
})
export class TemplatesMessageStatusTypePipe implements PipeTransform {

  private readonly statusTranslations: { [key: string]: string } = {
    APPROVED: 'Approvato',
    IN_APPEAL: 'In Ricorso',
    REJECTED: 'Rifiutato',
    PENDING: 'In Attesa',
    PENDING_DELETION: 'In Attesa di Eliminazione',
    DELETED: 'Eliminato',
    DISABLED: 'Disabilitato',
    PAUSED: 'In Pausa',
    LIMIT_EXCEEDED: 'Limite Superato',
    NOT_SENT: 'Non Inviato'
  };

  transform(value: string): string {
    return this.statusTranslations[value] || value;
  }

}
