import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { LibraryComponent } from 'src/app/modules/shared/library/library.component';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelLibraryService } from '../../commons/services/backend/laravel-library.service';
import * as LibraryActions from '../actions/library.actions';
import { AppState } from '../reducers';
import * as LibrarySelectors from '../selectors/library.selectors';

@Injectable()
export class LibraryEffects {

  error$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.listFilesFailed, LibraryActions.uploadFileFailed, LibraryActions.removeFileFailed, LibraryActions.resolveUrlFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore:', error);
        }
      })
    )
  }, { dispatch: false }
  );

  //FILES
  listFiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.listFiles),
      concatLatestFrom(() => this.store$.select(LibrarySelectors.getSessionId)),
      map(([{ sessionId, temp }, idFromStore]) => {
        return { sessionId: sessionId || idFromStore, temp }
      }),
      filter(sessionId => !!sessionId),
      switchMap(({ sessionId, temp }) => {
        return this.libraryService.listFiles(sessionId, temp)
          .pipe(
            map(results =>
              LibraryActions.listFilesCompleted({ files: results })
            ),
            catchError(error => {
              return of(LibraryActions.listFilesFailed({ error }))
            })
          )
      })
    )
  }
  );

  uploadFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.uploadFile),
      filter(({ file }) => !!file),
      concatLatestFrom(() => this.store$.select(LibrarySelectors.getSessionId)),
      map(([{ sessionId, file, temp }, idFromStore]) => {
        return {
          sessionId: sessionId || idFromStore, file, temp
        }
      }),
      filter(sessionId => !!sessionId),
      switchMap(({ sessionId, file, temp }) =>
        this.libraryService.uploadFile(sessionId, file, temp)
          .pipe(
            map(result =>
              LibraryActions.uploadFileCompleted({ sessionId, path: result, temp })
            ),
            catchError(error => of(LibraryActions.uploadFileFailed({ error })))
          )
      )
    )
  }
  );

  onUploadFileCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.uploadFileCompleted),
      tap(() => this.alertService.showConfirmMessage(`File caricato con successo`)),
      map(({ sessionId, temp }) => LibraryActions.listFiles({ sessionId, temp }))
    )
  }
  );

  removeFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.removeFile),
      concatLatestFrom(() => this.store$.select(LibrarySelectors.getSessionId)),
      map(([{ sessionId, filename, temp }, idFromStore]) => {
        return {
          sessionId: sessionId || idFromStore, filename, temp
        }
      }),
      filter(sessionId => !!sessionId),
      switchMap(({ sessionId, filename, temp }) =>
        this.alertService.showConfirmDialog('Elimina media', `Se lo stesso media è stato usato in altri messaggi ancora in attesa l'invio fallirà.<br>Sei sicuro di voler eliminare il file "${filename}"?`)
          .pipe(
            filter(confirm => !!confirm),
            switchMap(() =>
              this.libraryService.removeFile(sessionId, filename, temp).pipe(
                map(() =>
                  LibraryActions.removeFileCompleted({ sessionId, temp })
                ),
                catchError(error => of(LibraryActions.removeFileFailed({ error })))
              )
            )
          )
      )
    )
  }
  );

  onRemoveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.removeFileCompleted),
      tap(() => this.alertService.showConfirmMessage(`File rimosso con successo`)),
      map(({ sessionId, temp }) => LibraryActions.listFiles({ sessionId, temp }))
    )
  }
  );

  openLibrary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.openLibrary),
      map(({ temp, fileType }) => {
        let dialogRef = this.dialog.open(LibraryComponent, { width: '80%', data: { temp, fileType } });
        return LibraryActions.libaryDialogOpened({ dialogId: dialogRef.id });
      }))
  }
  );

  loadFilesOnOpenLibrary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.openLibrary),
      map(({ temp }) => LibraryActions.listFiles({ temp })))
  }
  );

  resolveUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.resolveUrl),
      filter(({ filename }) => !!filename),
      concatLatestFrom(() => this.store$.select(LibrarySelectors.getSessionId)),
      map(([{ sessionId, filename, temp }, idFromStore]) => {
        return {
          sessionId: sessionId || idFromStore, filename, temp
        }
      }),
      filter(sessionId => !!sessionId),
      switchMap(({ sessionId, filename, temp }) =>
        this.libraryService.fileUrl(sessionId, filename, temp)
          .pipe(
            map(url =>
              LibraryActions.resolveUrlCompleted({ sessionId, url })
            ),
            catchError(error => of(LibraryActions.resolveUrlFailed({ error })))
          )
      )
    )
  }
  );

  closeLibraryAfterUrlResolve$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.resolveUrlCompleted),
      map(() => LibraryActions.closeLibraryDialog()))
  }
  );

  closeLibraryDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.closeLibraryDialog),
      concatLatestFrom(() => this.store$.select(LibrarySelectors.getLibraryDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId)?.close();
        }
      })
    )
  }, { dispatch: false }
  );

  download$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.downloadFile),
      filter(({ filename }) => !!filename),
      concatLatestFrom(() => this.store$.select(LibrarySelectors.getSessionId)),
      map(([{ sessionId, filename, temp }, idFromStore]) => {
        return {
          sessionId: sessionId || idFromStore, filename, temp
        }
      }),
      filter(sessionId => !!sessionId),
      switchMap(({ sessionId, filename, temp }) =>
        this.libraryService.fileUrl(sessionId, filename, temp)
          .pipe(
            map(url =>
              LibraryActions.downloadFileCompleted({ filename, url })
            ),
            catchError(error => of(LibraryActions.resolveUrlFailed({ error })))
          )
      )
    )
  }
  );

  downloadCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.downloadFileCompleted),
      filter(({ url }) => !!url),
      tap(({ filename, url }) => saveAs(url, filename))
    )
  }, { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private store$: Store<AppState>,
    private libraryService: LaravelLibraryService,
    private alertService: AlertService
  ) { }
}
