import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, filter, map, Observable, startWith } from 'rxjs';

import { User } from '../../../commons/models/user.model';
import { BIG_PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { UserFilters } from './../../../commons/models/user.model';

export type UsersColumn = "id" | "name" | "email" | "partner" | "role" | "actions";

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  users: Observable<User[]>;

  tooltipsMap: Map<number, BehaviorSubject<string>> = new Map();
  tooltipText: string;
  @Input()
  defaultFilters: UserFilters;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: UsersColumn[] = ["id", "name", "email", "partner", "role", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Input()
  hideFilters = false;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<UserFilters> = new EventEmitter<UserFilters>();

  @Output()
  onSelectUser: EventEmitter<User> = new EventEmitter<User>();
  @Output()
  onAddUser: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditUser: EventEmitter<User> = new EventEmitter<User>();

  nameFilterCtrl: FormControl = new FormControl<string>('');

  filteredUsers$: Observable<User[]>;

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });

    this.filteredUsers$ = combineLatest(
      [
        this.users.pipe(filter(users => users ? users.length > 0 : false)),
        this.nameFilterCtrl.valueChanges.pipe(startWith(''), map(filter => filter || '')),
      ]
    ).pipe(
      map(([users, filter]) => users.filter(user => user.name.toLowerCase().includes(filter.toLowerCase()) || user.partnerName?.toLowerCase().includes(filter.toLowerCase()))),
    )

    this.users.subscribe(users => {
      users.forEach(user => {
        if (user.role === 'partner' && !this.tooltipsMap.has(user.id)) {
          this.tooltipsMap.set(user.id, new BehaviorSubject<string>('Caricamento...'));
          this.updateUserTooltip(user);
        }
      });
    });
  }

  addUser() {
    this.onAddUser.emit();
  }

  editUser(user: User) {
    this.onEditUser.emit(user);
  }

  selectUser(user: User) {
    this.onSelectUser.emit(user);
  }

  onFilter(filters: UserFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  updateUserTooltip(partner: User) {
    this.users.pipe(
      map(users => users.filter(user => user.partner?.id === partner.id))
    ).subscribe(partnerClients => {
      const activeWithSubscription = partnerClients.filter(client => client.active && (client.profile === "Subscription Mensile" || client.profile === "Subscription Annuale"));
      const expiringUsers = partnerClients.filter(client => client.active && client.profile === "Fase di Test" && client.expirationDate);
      const nonExpiringUsers = partnerClients.filter(client => client.active && client.profile === "Fase di Test" && !client.expirationDate);
      const freeUsers = partnerClients.filter(client => client.profile === "Account gratuito");
      const inactiveUsers = partnerClients.filter(client => !client.active);

      const tooltipText = `
        - Utenti attivi (subscription): ${activeWithSubscription.length}
        - Test con scadenza: ${expiringUsers.length}
        - Test senza scadenza: ${nonExpiringUsers.length}
        - Gratuiti: ${freeUsers.length}
        - Inattivi: ${inactiveUsers.length}
      `;

      this.tooltipsMap.get(partner.id)?.next(tooltipText);
    });
  }

  getUserTooltip(partnerId: number): Observable<string> {
    return this.tooltipsMap.get(partnerId)?.asObservable() ?? new BehaviorSubject<string>('Caricamento...').asObservable();
  }


}
