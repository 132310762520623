import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { combineLatest, filter, map, Observable, startWith } from 'rxjs';
import { Client, ClientFilters } from 'src/app/commons/models/client.model';

import { BIG_PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';


export type ClientsColumn = "id" | "nome"| "fatturazione" | "crediti" | "status" | "telefono" | "messaggiAttivi" | "sessionId" | "sessione" | "actions";

@Component({
  selector: 'client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  clients: Observable<Client[]>;

  @Input()
  defaultFilters: ClientFilters;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ClientsColumn[] = ["id", "nome", "fatturazione", "crediti", "status", "telefono", "messaggiAttivi", "sessionId", "sessione", "actions"];


  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ClientFilters> = new EventEmitter<ClientFilters>();

  @Output()
  onSelectClient: EventEmitter<Client> = new EventEmitter<Client>();
  @Output()
  onAddClient: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditClient: EventEmitter<Client> = new EventEmitter<Client>();

  nameFilterCtrl: FormControl = new FormControl<string>('');

  filteredClients: Observable<Client[]>;

  constructor() { }

  ngAfterViewInit() {
    this.sort?.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator?.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });

    this.filteredClients = combineLatest(
      [
        this.clients.pipe(filter(clients => clients ? clients.length > 0 : false)),
        this.nameFilterCtrl.valueChanges.pipe(startWith(''), map(filter => filter || '')),
      ]
    ).pipe(
      map(([clients, filter]) => clients.filter(client => client.nome.toLowerCase().includes(filter.toLowerCase()) || client?.user?.partnerName?.toLowerCase().includes(filter.toLowerCase()))),
    )
  }

  addClient() {
    this.onAddClient.emit();
  }

  editClient(client: Client) {
    this.onEditClient.emit(client);
  }

  selectClient(client: Client) {
    this.onSelectClient.emit(client);
  }

  onFilter(filters: ClientFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
