<app-blacklist-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-blacklist-filters>
<mat-card class="u-center-text" *ngIf="!(blacklists | async).length">
  <h4>Nessuna blacklist trovata</h4>
  <button mat-raised-button (click)="addBlacklist()">Aggiungi</button>
</mat-card>
<div [hidden]="!(blacklists | async).length">
  <table mat-table matSort [dataSource]="blacklists">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let blacklist">{{ blacklist.id }}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Telefono</th>
      <td mat-cell *matCellDef="let blacklist">
        {{ blacklist.phone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="soft_block">
      <th mat-header-cell *matHeaderCellDef>Consenti sequenze</th>
      <td mat-cell *matCellDef="let blacklist">
        <mat-icon *ngIf="blacklist.softBlock">done_outline</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef>Data di Creazione</th>
      <td mat-cell *matCellDef="let blacklist">
        {{ blacklist.createdAt | date: 'dd/MM/yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>Utente</th>
      <td mat-cell *matCellDef="let blacklist">
        {{ blacklist.user?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button mat-raised-button color="primary" (click)="$event.stopPropagation(); addBlacklist()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let blacklist">
        <ng-container>
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editBlacklist(blacklist)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>