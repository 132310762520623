export function toFormData<T>(formValue: T, post = false) {
  const formData = new FormData();

  for (const key of Object.keys(formValue)) {
    let value = formValue[key];
    if (value) {
      if (typeof value === 'boolean') {
        value = value ? 1 : 0;
      }
      if (typeof value === 'object' && !(value instanceof File)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    }
  }

  if (post) {
    formData.append('_method', 'POST');
  }

  return formData;
}
