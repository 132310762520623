import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { map, Subject, takeUntil } from 'rxjs';
import { CategoryFileType, TemplatesMessage } from 'src/app/commons/models/templates-messages.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { matchAll } from 'src/app/helpers/string.utils';
import * as TemplatesMessageActions from 'src/app/store/actions/templates-messages.actions';
import { AppState } from 'src/app/store/reducers';
import * as TemplatesMessageSelectors from 'src/app/store/selectors/templates-messages.selectors';
import * as LibraryActions from 'src/app/store/actions/library.actions';
import { Actions, ofType } from '@ngrx/effects';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'whatsreminder-template-message-edit',
  templateUrl: './template-message-edit.component.html',
  styleUrls: ['./template-message-edit.component.scss']
})
export class TemplateMessageEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _templateMessage: TemplatesMessage;
  public sessionId: number;
  @ViewChild("body") messageInput;
  @ViewChild("header") messageInput2;
  isEmojiPickerVisible: boolean;
  isEmojiPickerVisibleHeader: boolean;

  emojiPickerConf = {
    i18n: {
      search: 'Cerca',
      emojilist: 'Lista emoji',
      notfound: 'Nessuna Emoji trovata',
      clear: 'Cancella',
      categories: {
        search: 'Risultati ricerca',
        recent: 'Frequenti',
        people: 'Smileys & People',
        nature: 'Animals & Nature',
        foods: 'Food & Drink',
        activity: 'Activity',
        places: 'Travel & Places',
        objects: 'Objects',
        symbols: 'Symbols',
        flags: 'Flags',
        custom: 'Custom',
      }
    }
  }

  templateMessageForm: UntypedFormGroup;
  headerType: string;
  showHeaderSection: boolean = true;

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private actions$: Actions,
  ) {
    this.actions$
      .pipe(
        ofType(LibraryActions.resolveUrlCompleted),
        map(({ url }) => url),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((fileUrl) => {
        if (this.templateMessageForm) {
          const fileName = fileUrl.split('/').pop();
          const fileExtension = fileName.includes('.') ? fileName.split('.').pop().toLowerCase() : '';

          const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp'];
          const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm'];
          const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'];

          if (imageExtensions.includes(fileExtension)) {
            this.templateMessageForm.get("imageHeader").patchValue(fileUrl);
            this.templateMessageForm.get("imageHeader").markAsDirty();
          } else if (videoExtensions.includes(fileExtension)) {
            this.templateMessageForm.get("videoHeader").patchValue(fileUrl);
            this.templateMessageForm.get("videoHeader").markAsDirty();
          } else if (documentExtensions.includes(fileExtension)) {
            this.templateMessageForm.get("documentHeader").patchValue(fileUrl);
            this.templateMessageForm.get("documentHeader").markAsDirty();
          } else {
            this.alertService.showErrorMessage('Errore', 'Il tipo di file non è supportato');
          }
        }
      });
  }

  ngOnInit() {
    if (this.data) {
      this.templateMessage = this.data.templateMessage;
      this.sessionId = this.data.sessionId;
    }

    this.store$.pipe(
      select(TemplatesMessageSelectors.getSessionId),
      takeUntil(this.unsubscribe$)
    ).subscribe((sessionId) => {
      this.sessionId = sessionId;
    });


    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    this.templateMessageForm = this.fb.group({
      name: ["", [Validators.required, Validators.pattern('^[a-z]+$')]],
      category: ["", Validators.required],
      header: [""],
      headerType: ["", Validators.required],
      body: [""],
      footer: ["", Validators.required],
      button: [""],
      imageHeader: [""],
      videoHeader: [""],
      documentHeader: [""],
      status: [""],
      externalId: [""],
      date_sent: [""],
      body_variables: this.fb.array([]),
      header_variables: this.fb.array([]),
    });

    this.templateMessageForm.get('headerType').valueChanges.subscribe((selectedType: CategoryFileType | 'TEXT') => {
      this.templateMessageForm.get('header_variables').reset();
      this.templateMessageForm.get('header_variables').clearValidators();
      this.templateMessageForm.get('header_variables').updateValueAndValidity();
      this.variablesArrayHeader.clear();

      this.templateMessageForm.get('imageHeader').clearValidators();
      this.templateMessageForm.get('videoHeader').clearValidators();
      this.templateMessageForm.get('documentHeader').clearValidators();

      switch (selectedType) {
        case 'IMAGE':
          this.templateMessageForm.get('imageHeader').setValidators([Validators.required]);
          this.templateMessageForm.get('header').reset();
          this.templateMessageForm.get('header').clearValidators();
          this.templateMessageForm.get('header').updateValueAndValidity();
          break;
        case 'VIDEO':
          this.templateMessageForm.get('videoHeader').setValidators([Validators.required]);
          this.templateMessageForm.get('header').reset();
          this.templateMessageForm.get('header').clearValidators();
          this.templateMessageForm.get('header').updateValueAndValidity();
          break;
        case 'DOCUMENT':
          this.templateMessageForm.get('documentHeader').setValidators([Validators.required]);
          this.templateMessageForm.get('header').reset();
          this.templateMessageForm.get('header').clearValidators();
          this.templateMessageForm.get('header').updateValueAndValidity();
          break;
      }
      this.templateMessageForm.get('imageHeader').updateValueAndValidity();
      this.templateMessageForm.get('videoHeader').updateValueAndValidity();
      this.templateMessageForm.get('documentHeader').updateValueAndValidity();

    })
  }

  private ngOnChanges() {
    if (this.templateMessageForm) {
      this.resetForm();

      if (this.templateMessage) {
        this._initFormValues(this.templateMessage);
      }
    }
  }

  onCategoryChange() {
    const category = this.templateMessageForm.get('category').value;
    this.showHeaderSection = category !== 'AUTHENTICATION';
    if (!this.showHeaderSection) {
      this.templateMessageForm.get('headerType').clearValidators();
      this.templateMessageForm.get('headerType').updateValueAndValidity();
      this.templateMessageForm.get('headerType').reset();

      this.templateMessageForm.get('header_variables').clearValidators();
      this.templateMessageForm.get('header_variables').updateValueAndValidity();
      this.templateMessageForm.get('header_variables').reset();

      this.templateMessageForm.get('header').clearValidators();
      this.templateMessageForm.get('header').updateValueAndValidity();
      this.templateMessageForm.get('header').reset();

      this.templateMessageForm.get('footer').clearValidators();
      this.templateMessageForm.get('footer').updateValueAndValidity();
      this.templateMessageForm.get('footer').reset();

      this.templateMessageForm.get('body_variables').clearValidators();
      this.templateMessageForm.get('body_variables').updateValueAndValidity();
      this.templateMessageForm.get('body_variables').reset();

      this.templateMessageForm.get('imageHeader').clearValidators();
      this.templateMessageForm.get('imageHeader').reset();
      this.templateMessageForm.get('videoHeader').clearValidators();
      this.templateMessageForm.get('videoHeader').reset();
      this.templateMessageForm.get('documentHeader').clearValidators();
      this.templateMessageForm.get('documentHeader').reset();

      this.variablesArrayHeader.clear();
    } else {
      this.templateMessageForm.get('headerType').setValidators(Validators.required);
      this.templateMessageForm.get('header').updateValueAndValidity();
      this.templateMessageForm.get('header_variables').updateValueAndValidity();
      this.templateMessageForm.get('footer').updateValueAndValidity();
      this.templateMessageForm.get('body_variables').updateValueAndValidity();
    }
  }


  private _initFormValues(templateMessage: TemplatesMessage): void {
    if (templateMessage) {
      this.templateMessageForm.patchValue({
        name: templateMessage.name,
        category: templateMessage.category,
        header: templateMessage.header,
        body: templateMessage.body,
        footer: templateMessage.footer,
        button: templateMessage.button,
        status: templateMessage.status,
        headerType: templateMessage.headerType,
        sessionId: this.sessionId,
        externalId: templateMessage.externalId,
        dateSent: templateMessage.dataSent,
        imageHeader: templateMessage.imageHeaderUrl,
        videoHeader: templateMessage.videoHeaderUrl,
        documentHeader: templateMessage.documentHeaderUrl,
      });

      if (templateMessage.bodyVariables) {
        templateMessage.bodyVariables.forEach(variable => {
          this.variablesArray.push(this.getVariableGroup(variable.name, variable.example_value, variable.index));
        });
      }

      if (templateMessage.headerVariables) {
        templateMessage.headerVariables.forEach(variable => {
          this.variablesArrayHeader.push(this.getVariableGroup(variable.name, variable.example_value, variable.index));
        });
      }

      this.onCategoryChange();
    }
  }

  get templateMessage(): TemplatesMessage {
    return this._templateMessage;
  }

  set templateMessage(templateMessage: TemplatesMessage) {
    this._templateMessage = templateMessage;
  }

  private _prepareSaveTemplateMessage(): TemplatesMessage {
    const formValue = this.templateMessageForm.value;
    const savingTemplateMessage: TemplatesMessage = TemplatesMessage.fromFormGroup(this.templateMessageForm, this.templateMessage);

    savingTemplateMessage.sessionId = this.sessionId;
    savingTemplateMessage.body = formValue.body;
    savingTemplateMessage.bodyVariables = formValue.body_variables;
    savingTemplateMessage.header = formValue.header;
    savingTemplateMessage.headerVariables = formValue.header_variables;
    savingTemplateMessage.headerType = formValue.headerType;
    savingTemplateMessage.imageHeader = formValue.imageHeader;
    savingTemplateMessage.videoHeader = formValue.videoHeader;
    savingTemplateMessage.documentHeader = formValue.documentHeader;
    savingTemplateMessage.footer = formValue.footer;
    savingTemplateMessage.button = formValue.button;
    savingTemplateMessage.status = 'NOT_SENT';
    savingTemplateMessage.category = formValue.category;
    savingTemplateMessage.dataSent = formValue.dateSent ? formValue.dateSent : null;
    savingTemplateMessage.externalId = formValue.externalId;
    return savingTemplateMessage;
  }

  save() {
    let unsavedTemplateMessage = this._prepareSaveTemplateMessage();
    this.store$.dispatch(TemplatesMessageActions.saveTemplateMessage({ templateMessage: unsavedTemplateMessage.toDTO() }));
  }

  close() {
    if (this.templateMessageForm.pristine) {
      this.store$.dispatch(TemplatesMessageActions.closeTemplateMessageDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(TemplatesMessageActions.closeTemplateMessageDialog())
          }
        });
    }
  }

  deleteTemplateMessage() {
    if (this.templateMessage) {
      this.store$.dispatch(TemplatesMessageActions.deleteTemplateMessage({ templateMessage: this.templateMessage.toDTO() }));
    }
  }

  revert() {
    this.ngOnChanges();
  }

  public addEmoji(event) {
    const value = event.emoji.native;
    const oldValue = this.templateMessageForm.get('body').value ?? '';
    const cursorPosition = this.messageInput.nativeElement.selectionStart;
    const newValue = [oldValue.slice(0, cursorPosition), value, oldValue.slice(cursorPosition)].join('');
    this.templateMessageForm.get('body').patchValue(newValue);
    this.isEmojiPickerVisible = false;
    this.messageInput.nativeElement.focus();
    this.messageInput.nativeElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
  }

  public addEmojiHeader(event) {
    const value = event.emoji.native;
    const oldValue = this.templateMessageForm.get('header').value ?? '';
    const cursorPosition = this.messageInput2.nativeElement.selectionStart;
    const newValue = [oldValue.slice(0, cursorPosition), value, oldValue.slice(cursorPosition)].join('');
    this.templateMessageForm.get('header').patchValue(newValue);
    this.isEmojiPickerVisible = false;
    this.messageInput2.nativeElement.focus();
    this.messageInput2.nativeElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
  }

  insertParenthesis() {
    const oldValue = this.templateMessageForm.get('body').value ?? '';
    const cursorPosition = this.messageInput.nativeElement.selectionStart;
    const newValue = [oldValue.slice(0, cursorPosition), '{{}}', oldValue.slice(cursorPosition)].join('');
    this.templateMessageForm.get('body').patchValue(newValue);
    this.messageInput.nativeElement.focus();
    this.messageInput.nativeElement.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
  }

  insertParenthesisHeader() {
    const currentHeader = this.templateMessageForm.get('header').value;
    if (currentHeader.includes('{{') && currentHeader.includes('}}')) {
      this.alertService.showErrorMessage('Attenzione', 'Non è possibile inserire più di una variabile nell\'header');
      return;
    }
    const oldValue = this.templateMessageForm.get('header').value ?? '';
    const cursorPosition = this.messageInput2.nativeElement.selectionStart;
    const newValue = [oldValue.slice(0, cursorPosition), '{{}}', oldValue.slice(cursorPosition)].join('');
    this.templateMessageForm.get('header').patchValue(newValue);
    this.messageInput2.nativeElement.focus();
    this.messageInput2.nativeElement.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
  }

  onMessageFocusOut() {
    const variables = matchAll(/{{(.*?)}}/g, this.templateMessageForm.get('body').value);
    const groups: FormGroup[] = []
    variables.forEach((variable, index) => {
      const existing = this.variablesArray.controls.find((c, i) => c.get('name').value === variable);
      groups.push(this.getVariableGroup(variable, !!existing ? existing.get('example_value').value : '', index));
    });
    this.variablesArray.clear();
    groups.forEach((group) => {
      this.variablesArray.push(group);
    });
  }

  onMessageFocusOutHeader() {
    const variables = matchAll(/{{(.*?)}}/g, this.templateMessageForm.get('header').value);
    const groups: FormGroup[] = []
    variables.forEach((variable, index) => {
      const existing = this.variablesArrayHeader.controls.find((c, i) => c.get('name').value === variable);
      groups.push(this.getVariableGroup(variable, !!existing ? existing.get('example_value').value : '', index));
    });
    this.variablesArrayHeader.clear();
    groups.forEach((group) => {
      this.variablesArrayHeader.push(group);
    });
  }

  private getVariableGroup(name: string, example_value: string, index: number): FormGroup {
    return this.fb.group({
      name: [name],
      example_value: [example_value, Validators.required],
      index: [index, Validators.required]
    });
  }

  get variablesArray(): FormArray {
    return this.templateMessageForm.get('body_variables') as FormArray;
  }

  get variablesArrayHeader(): FormArray {
    return this.templateMessageForm.get('header_variables') as FormArray;
  }

  resetForm() {
    this.templateMessageForm.reset();
    this.variablesArray.clear();
    this.variablesArrayHeader.clear();
  }

  openLibrary() {
    const selectedType = this.templateMessageForm.controls['headerType'].value;
      switch (selectedType) {
        case 'DOCUMENT':
        this.store$.dispatch(LibraryActions.openLibrary({ temp: true, fileType: 'DOCUMENT' }));
        break;
        case 'VIDEO':
        this.store$.dispatch(LibraryActions.openLibrary({ temp: true, fileType: 'VIDEO' }));
        break;
        case 'IMAGE':
        this.store$.dispatch(LibraryActions.openLibrary({ temp: true, fileType: 'IMAGE' }));
        break;
        default:
        this.store$.dispatch(LibraryActions.openLibrary({ temp: true }));
        break;
      }
  }
}
