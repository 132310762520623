import { createAction, props } from '@ngrx/store';

import { User, UserDTO, UserFilters } from '../../commons/models/user.model';

export const loadUsers = createAction('[Users] Load users', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: UserFilters, includes?: string[] }>());
export const loadUsersCompleted = createAction('[Users] Load users Completed', props<{ users: UserDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: UserFilters, includes?: string[] }>());
export const loadUsersFailed = createAction('[Users] Load users Failed', props<{ error: any }>());

export const changePage = createAction('[Users] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Users] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Users] Change filters', props<{ filters: UserFilters }>());

export const editUser = createAction('[Users] Edit user', props<{ user: User }>());
export const userDialogOpened = createAction('[Users] Detail dialog opened', props<{ dialogId: string }>());
export const closeUserDialog = createAction('[Users] Close detail dialog');

export const saveUser = createAction('[Users] Save user', props<{ user: User }>());
export const saveUserCompleted = createAction('[Users] Save user completed', props<{ user: UserDTO }>());
export const saveUserFailed = createAction('[Users] Save user failed', props<{ error: any }>());

export const deleteUser = createAction('[Users] Delete user', props<{ user: UserDTO }>());
export const deleteUserCompleted = createAction('[Users] Delete user completed', props<{ user: UserDTO }>());
export const deleteUserCancelled = createAction('[Users] Delete user cancelled');
export const deleteUserFailed = createAction('[Users] Delete user failed', props<{ error: any }>());

export const changeUserPassword = createAction('[Users] Change user password', props<{ user?: UserDTO }>());
export const changePasswordDialogOpen = createAction('[Users] Change password dialog opened', props<{ dialogId: string }>());
export const closeChangePasswordDialog = createAction('[Users] Close change password dialog');

export const updatePassword = createAction('[Users] Update password', props<{ newPassword: string, user?: UserDTO }>());
export const updatePasswordCompleted = createAction('[Users] Update password completed', props<{ user: UserDTO }>());
export const updatePasswordFailed = createAction('[Users] Update password failed', props<{ error: any }>());

export const showPaymentInfo = createAction('[Users] Show payment info', props<{ user?: UserDTO }>());
export const paymentInfoDialogOpen = createAction('[Users] Payment info dialog opened', props<{ dialogId: string }>());
export const closePaymentInfoDialog = createAction('[Users] Close payment info dialog');

export const selectUser = createAction('[Users] Select user', props<{ filters?: UserFilters, currentFormControlName: string }>());
export const selectionDialogOpened = createAction('[Users] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Users] Close selection dialog');
export const userSelected = createAction('[Users] User selected', props<{ user: UserDTO }>());

export const loadSelectionUsersCompleted = createAction('[Users] Load selection users Completed', props<{ users: UserDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: UserFilters, includes?: string[] }>());
export const changeSelectionPage = createAction('[Users] Change selection page', props<{ page: number, size: number }>());
export const changeSelectionSort = createAction('[Users] Change selection sort', props<{ order: string, direction: string }>());
export const changeSelectionFilters = createAction('[Users] Change selection filters', props<{ filters: UserFilters }>());

export const testDisconnectionWebhook = createAction('[User] Test disconnection webhook', props<{ user: UserDTO }>());
export const testDisconnectionWebhookCompleted = createAction('[User] Test disconnection webhook completed', props<{ user: UserDTO }>());
export const testDisconnectionWebhookFailed = createAction('[User] Test disconnection webhook failed', props<{ error: any }>());

export const testMessageFailedWebhook = createAction('[User] Test message failed webhook', props<{ user: UserDTO }>());
export const testMessageFailedWWebhookCompleted = createAction('[User] Test message failed webhook completed', props<{ user: UserDTO }>());
export const testMessageFailedWWebhookFailed = createAction('[User] Test message failed webhook failed', props<{ error: any }>());
