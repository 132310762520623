import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { TemplatesMessageDTO, TemplatesMessageFilters } from '../../models/templates-messages.model';


@Injectable({
  providedIn: "root"
})
export class LaravelTemplatesMessageService {

  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/templatesMessage",
      store: environment.baseUrl + "/api/templateMessage",
      show: environment.baseUrl + "/api/templateMessage",
      update: environment.baseUrl + "/api/templateMessage",
      clone: environment.baseUrl + "/api/templateMessage/clone",
      destroy: environment.baseUrl + "/api/templateMessage",
      send: environment.baseUrl + "/api/templateMessage/send",
      retrive: environment.baseUrl + "/api/templateMessage/retrive",
    };
  }

  public list(
    session_id: number,
    page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filters?: TemplatesMessageFilters,
  ): Observable<ListResultDTO<TemplatesMessageDTO>> {


    let params = {};
    params["session_id"] = "" + session_id;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;
      if (filters.ids) params["ids[]"] = filters.ids;
      if (filters.status) params["status"] = "" + filters.status;
      if (filters.category) params["category"] = "" + filters.category;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<TemplatesMessageDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<TemplatesMessageDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public upsert(templatesMessage: TemplatesMessageDTO): Observable<TemplatesMessageDTO> {
    if (templatesMessage.id) {
      return this.httpClient.put<TemplatesMessageDTO>(this.ROUTES.store, { templatesMessage });
    } else {
      return this.httpClient.post<TemplatesMessageDTO>(this.ROUTES.store, { templatesMessage });
    }
  }

  public clone(id: number): Observable<TemplatesMessageDTO> {
    return this.httpClient.post<TemplatesMessageDTO>(this.ROUTES.clone, { id });
  }

  public show(id: number): Observable<TemplatesMessageDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<TemplatesMessageDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public retrive(id: number): Observable<TemplatesMessageDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<TemplatesMessageDTO>(this.ROUTES.retrive, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public delete(id: number): Observable<TemplatesMessageDTO> {
    let params = { id: "" + id };
    return this.httpClient.delete<TemplatesMessageDTO>(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public send(id: number, waNumId: string, waToken: string): Observable<TemplatesMessageDTO> {
    const body = {
      id: id,
      wa_num_id: waNumId,
      wa_token: waToken
    };
    return this.httpClient.post<TemplatesMessageDTO>(this.ROUTES.send, body);
  }

}
