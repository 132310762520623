<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="templateMessage?.id">Modifica Modello Messaggio: {{ templateMessage.name }}</span>
    <span *ngIf="!templateMessage?.id">Nuovo Modello Messaggio</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="templateMessageForm">
    <div class="formGroup__wrapper delay_container">
      <mat-label class="formGroup__label"> Informazioni Generali </mat-label>
      <div class="form-row">
        <mat-form-field class="u-full-width">
          <input matInput pattern="^[a-z]+$" placeholder="Nome del modello" formControlName="name" type="text"
            required />
          <mat-error *ngIf="templateMessageForm.get('name').hasError('required')">
            Campo obbligatorio
          </mat-error>
          <mat-error *ngIf="templateMessageForm.get('name').hasError('pattern')">
            Il nome non può contenere spazi, numeri o lettere maiuscole.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="formGroup u-full-width">
          <mat-label>Categoria</mat-label>
          <mat-select formControlName="category" (selectionChange)="onCategoryChange()">
            <mat-option value="MARKETING">Marketing</mat-option>
            <mat-option value="UTILITY">Utility</mat-option>
            <mat-option value="AUTHENTICATION">Autenticazione</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="formGroup__wrapper delay_container" *ngIf="showHeaderSection">
      <mat-label class="formGroup__label"> Header </mat-label>

      <div class="u-flex-full text-container">
        <mat-form-field class="formGroup u-group-full-width">
          <mat-label>Tipo di Header</mat-label>
          <!-- <mat-select formControlName="headerType" (selectionChange)="onHeaderTypeChange()"> -->
          <mat-select formControlName="headerType">
            <mat-option value="TEXT">Testo</mat-option>
            <mat-option value="IMAGE">Immagine</mat-option>
            <mat-option value="VIDEO">Video</mat-option>
            <mat-option value="DOCUMENT">Documenti</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="templateMessageForm.get('headerType')?.value === 'TEXT'">
        <div class="u-flex-full text-container">
          <mat-form-field class="formGroup u-group-full-width">
            <textarea #header matInput placeholder="Testo" formControlName="header" type="text" rows="2"
              (focusout)="onMessageFocusOutHeader()"></textarea>
            <mat-hint align="end" [matTooltip]="
                'Metti il nome dei campi dinamici tra doppie parentesi graffe. Ad es. {{fname}}'">Puoi usare campi
              dinamici<mat-icon>help</mat-icon>
            </mat-hint>
            <span class="emoji-button">
              <button mat-raised-button (click)="insertParenthesisHeader()">
                {{'{{ }}'}}
              </button>
              <button mat-raised-button (click)="isEmojiPickerVisibleHeader = !isEmojiPickerVisibleHeader">
                😀
              </button>
              <emoji-mart sheetSize="32" [showPreview]="true" [i18n]="emojiPickerConf.i18n"
                *ngIf="isEmojiPickerVisibleHeader" (emojiSelect)="addEmojiHeader($event)"
                title="Aggiungi emoji"></emoji-mart>
            </span>
          </mat-form-field>
        </div>
        <h4 *ngIf="variablesArrayHeader.length">Variabili (Inserire valori di esempio per le variabili)
          <span style="color: red; font-weight: bold; font-size: 10px; float: right;">Tutti i campi sono
            obbligatori</span>
        </h4>
        <div *ngFor="let variableCtrlHeader of variablesArrayHeader.controls">
          <div [formGroup]="variableCtrlHeader">
            <mat-form-field class="u-full-width">
              <label>Valore di default per: <b>{{ variableCtrlHeader.value.name }}</b></label>
              <input matInput type="text" formControlName="example_value" />
              <mat-error>Campo obbligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="u-flex-full" *ngIf="templateMessageForm.get('headerType')?.value === 'IMAGE'">
        <mat-form-field class="formGroup media-url u-full-width">
          <input matInput placeholder="Media URL" formControlName="imageHeader" type="text" />
          <mat-hint align="end" [matTooltip]="supportedMediaText">Formati accettati <mat-icon>help</mat-icon>
          </mat-hint>

          <mat-error>
            {{ templateMessageForm.get("imageHeader")?.errors?.error }}</mat-error>
        </mat-form-field>
          <div class="media-url" *ngIf="isMediaUrl">
            <a mat-raised-button href="{{ templateMessageForm.get('imageHeader').value }}" target="_blank">Anteprima Media</a>
          </div>

          <button mat-raised-button (click)="openLibrary()" matTooltip="Scegli un file dalla tua libreria">
            Libreria
          </button>
          <!-- <mat-label>Seleziona Immagine</mat-label> -->
          <!-- <mat-select formControlName="imageHeader" [compareWith]="compareLibraryTemplatesMessage">
            <mat-option value="Immagine" (click)="addNewFile()">Aggiungi Nuova Immagine</mat-option>
            <mat-option *ngFor="let template of libraryTemplatesMessage" [value]="template">
              {{template.name}}
            </mat-option>
          </mat-select> -->
      </div>

      <div class="u-flex-full" *ngIf="templateMessageForm.get('headerType')?.value === 'VIDEO'">
        <mat-form-field class="formGroup media-url u-full-width">
          <input matInput placeholder="Media URL" formControlName="videoHeader" type="text" />
          <mat-hint align="end" [matTooltip]="supportedMediaText">Formati accettati <mat-icon>help</mat-icon>
          </mat-hint>

          <mat-error>
            {{ templateMessageForm.get("videoHeader")?.errors?.error }}</mat-error>
        </mat-form-field>
          <div class="media-url" *ngIf="isMediaUrl">
            <a mat-raised-button href="{{ templateMessageForm.get('videoHeader').value }}" target="_blank">Anteprima Media</a>
          </div>

          <button mat-raised-button (click)="openLibrary()" matTooltip="Scegli un file dalla tua libreria">
            Libreria
          </button>
      </div>

      <div class="u-flex-full" *ngIf="templateMessageForm.get('headerType')?.value === 'DOCUMENT'">
        <mat-form-field class="formGroup media-url u-full-width">
          <input matInput placeholder="Media URL" formControlName="documentHeader" type="text" />
          <mat-hint align="end" [matTooltip]="supportedMediaText">Formati accettati <mat-icon>help</mat-icon>
          </mat-hint>

          <mat-error>
            {{ templateMessageForm.get("videoHeader")?.errors?.error }}</mat-error>
        </mat-form-field>
          <div class="media-url" *ngIf="isMediaUrl">
            <a mat-raised-button href="{{ templateMessageForm.get('documentHeader').value }}" target="_blank">Anteprima Media</a>
          </div>

          <button mat-raised-button (click)="openLibrary()" matTooltip="Scegli un file dalla tua libreria">
            Libreria
          </button>
      </div>

    </div>

    <div class="formGroup__wrapper delay_container" *ngIf="showHeaderSection">
      <mat-label class="formGroup__label"> Body </mat-label>
      <div class="u-flex-full text-container">
        <mat-form-field class="formGroup u-group-full-width">
          <textarea #body matInput placeholder="Testo del messaggio" formControlName="body" type="text" rows="5"
            (focusout)="onMessageFocusOut()"></textarea>
          <mat-hint align="end" [matTooltip]="
              'Metti il nome dei campi dinamici tra doppie parentesi graffe. Ad es. {{fname}}'">Puoi usare campi
            dinamici<mat-icon>help</mat-icon>
          </mat-hint>
          <span class="emoji-button">
            <button mat-raised-button (click)="insertParenthesis()">
              {{'{{ }}'}}
            </button>
            <button mat-raised-button (click)="isEmojiPickerVisible = !isEmojiPickerVisible">
              😀
            </button>
            <emoji-mart sheetSize="32" [showPreview]="true" [i18n]="emojiPickerConf.i18n" *ngIf="isEmojiPickerVisible"
              (emojiSelect)="addEmoji($event)" title="Aggiungi emoji"></emoji-mart>
          </span>
        </mat-form-field>
      </div>
      <h4 *ngIf="variablesArray.length">Variabili (Inserire valori di esempio per le variabili)
        <span style="color: red; font-weight: bold; font-size: 10px; float: right;">Tutti i campi sono
          obbligatori</span>
      </h4>
      <div *ngFor="let variableCtrl of variablesArray.controls">
        <div [formGroup]="variableCtrl">
          <mat-form-field class="u-full-width">
            <label>Valore di esempio per: <b>{{ variableCtrl.value.name }}</b></label>
            <input matInput type="text" formControlName="example_value" />
            <mat-error>Campo obbligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="showHeaderSection">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Footer" formControlName="footer" type="text" />
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div class="form-row" style="padding: 4% 0% 2% 0%; justify-content: space-between;">
      <div style="gap: 10px; display: flex;">
        <button mat-raised-button color="primary" (click)="save()"
          [disabled]="templateMessageForm.invalid">Salva</button>
        <button mat-raised-button (click)="revert()">Annulla</button>
      </div>
      <button mat-raised-button (click)="close()">Chiudi</button>
    </div>
  </form>
</mat-dialog-content>
