<client-filters *ngIf="false" (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</client-filters>

<mat-card class="u-center-text" *ngIf="!(clients | async).length">
  <h4>Nessun cliente trovato</h4>
  <!-- <button mat-raised-button (click)="addClient()">Aggiungi</button> -->
</mat-card>

<div [hidden]="!(clients | async).length">
  <table mat-table matSort [dataSource]="filteredClients">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let client">{{ client.id }}</td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          Nome
        </div>
        <mat-form-field>
          <input matInput [formControl]="nameFilterCtrl" type="text" />
          <button *ngIf="nameFilterCtrl.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="nameFilterCtrl.reset()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let client">
        <ng-container *showFor="['admin']">
          <span *ngIf="client?.user.partnerName" class="partner-badge"
            [style]="'background-color: ' + client.user.partnerColor">{{client.user.partnerName}}</span>
        </ng-container>
        {{ client.nome }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fatturazione">
      <th mat-header-cell *matHeaderCellDef >Profilo fatturazione</th>
      <td mat-cell *matCellDef="let client">
        {{ client.user.profile }}  <br>
        <span *ngIf="client.user.expirationDate" matTooltip="Scadenza" style="font-size: x-small;">
          Scadenza: {{ client.user.expirationDate | date: 'dd/MM/yyyy'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="crediti">
      <th mat-header-cell *matHeaderCellDef >Messaggi residui</th>
      <td mat-cell *matCellDef="let client">
        <div *ngIf="client.user.credits && !client.user.unlimitedCredits">
          {{ client.user.credits}} di {{ client.user.maxCredits }}
        </div>
        <div *ngIf="client.user.unlimitedCredits">
          Illimitati
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Stato</th>
      <td mat-cell *matCellDef="let client">
        <ng-container *ngIf="client.user.statusPhone && client?.sessions[0]?.tipologia !== 'official'">
          <div *ngIf="!client?.sessions[0]?.status" class="status-container">
            <div class="session-status offline"></div>
            Offline ({{ client?.sessions[0]?.statusLastCheck | dateFormat }})
          </div>
          <div *ngIf="client?.sessions[0]?.status" class="status-container">
            <div class="session-status online"></div>
            Online ({{ client?.sessions[0]?.statusLastCheck | dateFormat }})
          </div>
        </ng-container>

        <ng-container *ngIf="client?.sessions[0]?.tipologia === 'official'">
          <div class="status-container">
            <div class="session-status meta"></div>
            Meta
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="telefono">
      <th mat-header-cell *matHeaderCellDef>Telefono</th>
      <td mat-cell *matCellDef="let client">
        {{ client.telefono }}
      </td>
    </ng-container>

    <ng-container matColumnDef="messaggiAttivi">
      <th mat-header-cell *matHeaderCellDef>Sequenze Attive</th>
      <td mat-cell *matCellDef="let client">
        {{ client.messaggiAttivi }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sessionId">
      <th mat-header-cell *matHeaderCellDef>ID Sessione</th>
      <td mat-cell *matCellDef="let client">{{ client?.sessions[0]?.id }}</td>
    </ng-container>

    <ng-container matColumnDef="sessione">
      <th mat-header-cell *matHeaderCellDef>Sessione</th>
      <td mat-cell *matCellDef="let client">
        {{ client?.sessions[0]?.tipologia | sessionType }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <!-- <button mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation();addClient()">
          <mat-icon>playlist_add</mat-icon>
        </button> -->
      </th>
      <td mat-cell *matCellDef="let client"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectClient(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[2]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
