<mat-toolbar color="primary" class="fix-nav">
  <button *ngIf="!shouldHide" type="button" mat-icon-button (click)="toggleSidebar()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span *ngIf="shouldHide"></span>
  <!-- <span class="page-title">
  </span> -->
  <img class="logo" src="{{ logo }}" />
  <span>
    <button mat-icon-button [matMenuTriggerFor]="profile">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #profile="matMenu">
      <div *ngIf="currentUser" class="text-menu-item">
        {{ currentUser.name }}<br />
      </div>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="showPaymentInfo()">
        <mat-icon>credit_card</mat-icon>
        Info Pagamento
      </button>
      <button mat-menu-item (click)="showParams()">
        <mat-icon>password</mat-icon>
        Parametri
      </button>
      <button mat-menu-item (click)="editHooks()" *showFor="['admin','partner']">
        <mat-icon>share</mat-icon>
        Webhooks
      </button>
      <button mat-menu-item (click)="changePassword()">
        <mat-icon>vpn_key</mat-icon>
        Cambia Password
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        Esci
      </button>
    </mat-menu>
  </span>
</mat-toolbar>