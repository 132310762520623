<mat-dialog-content>
  <p class="library-title mt-0">Gestione Media</p>

  <div class="u-flex-full">
    <mat-form-field class="file-field">
      <ngx-mat-file-input
        [formControl]="fileCtrl"
        placeholder="Carica file"
        [accept]="acceptsString"
      >
        <mat-icon ngxMatFileInputIcon color="primary" style="font-size: 1.5rem"
          >folder</mat-icon
        >
      </ngx-mat-file-input>
      <mat-error> {{ fileCtrl.errors?.error }}</mat-error>
      <mat-hint
        >La dimensione massima consentita è di
        {{ maxSize | byteFormat }}</mat-hint
      >
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      [disabled]="fileCtrl.invalid"
      (click)="uploadFile()"
    >
      Aggiungi alla Libreria
    </button>
  </div>

  <p class="library-title">Libreria Media</p>
  <div *ngIf="files?.length">
    <div class="file u-flex-full" *ngFor="let file of files">
      <span>
        {{ file }}
      </span>

      <span>
        <button
          mat-raised-button
          color="primary"
          (click)="selectFile(file)"
          [disabled]="!isFileInCategory(file)"
          [matTooltip]="isFileInCategory(file) ? 'Scegli Media' : 'Scegli un file della categoria corretta' "
        >
          Scegli
        </button>

        <button
          mat-icon-button
          (click)="removeFile(file)"
          matTooltip="Rimuovi dalla libreria"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <button mat-icon-button (click)="download(file)" matTooltip="Scarica">
          <mat-icon>download_for_offile</mat-icon>
        </button>
      </span>
    </div>
  </div>
  <mat-card class="u-center-text no-message" *ngIf="!files?.length">
    <h4>Nessun file in libreria</h4>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <span class="fill-remaining"></span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>
