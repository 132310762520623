import { Action, createReducer, on } from '@ngrx/store';

import { User, UserDTO } from '../../commons/models/user.model';
import * as UserActions from '../actions/user.actions';
import { UserFilters } from './../../commons/models/user.model';

export interface UserState {
  list: UserDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: UserFilters,
  dialogId: string,
  changePasswordDialogId: string,
  selectionDialogId: string,
  currentFormControlName: string,
  user: User
  selectionState: {
    list: UserDTO[],
    total: number,
    includes: string[],
    currentPage: number,
    perPage: number,
    order: string,
    direction: string,
    filters: UserFilters,
  },
  hooksDialogId: string
};

const initialState: UserState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  changePasswordDialogId: null,
  selectionDialogId: null,
  currentFormControlName: null,
  user: null,
  selectionState: null,
  hooksDialogId: null
};

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsersCompleted, (state, { users, currentPage, total, perPage, order, direction, includes }): UserState => {
    return { ...state, list: users, currentPage, total, perPage, order, direction, includes };
  }),
  on(UserActions.userDialogOpened, (state, { dialogId }): UserState => {
    return { ...state, dialogId };
  }),
  on(UserActions.changePasswordDialogOpen, (state, { dialogId }): UserState => {
    return { ...state, changePasswordDialogId: dialogId };
  }),
  on(UserActions.changeFilters, (state, { filters }): UserState => {
    return { ...state, currentPage: 1, filters };
  }),
  on(UserActions.paymentInfoDialogOpen, (state, { dialogId }): UserState => {
    return { ...state, dialogId };
  }),
  on(UserActions.selectionDialogOpened, (state, { selectionDialogId }): UserState => {
    return { ...state, selectionDialogId };
  }),
  on(UserActions.hooksDialogOpened, (state, { dialogId }): UserState => {
    return { ...state, hooksDialogId: dialogId };
  }),
  on(UserActions.selectUser, (state, { filters, currentFormControlName }): UserState => {
    return {
      ...state, currentPage: 1, selectionState: { ...state.selectionState, filters }, currentFormControlName
    };
  }),
  on(UserActions.loadSelectionUsersCompleted, (state, { users, currentPage, total, perPage, order, direction, includes, filters }): UserState => {
    return { ...state, selectionState: { list: users, currentPage, total, perPage, order, direction, includes, filters } };
  }),
  on(UserActions.changeSelectionFilters, (state, { filters }): UserState => {
    return {
      ...state, currentPage: 1, selectionState: { ...state.selectionState, filters }
    };
  }),

);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

