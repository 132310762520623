import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/commons/models/user.model';
import { AppState } from 'src/app/store/reducers';
import { getCurrentUser } from 'src/app/store/selectors/auth.selectors';

import { Role } from './../../../commons/models/user.model';

@Directive({ selector: "[showFor]" })
export class ShowForDirective implements OnInit {
  private destroy$ = new Subject<any>();
  private _role: Role[];

  constructor(private templateRef: TemplateRef<any>, private store$: Store<AppState>, private viewContainer: ViewContainerRef) { }

  get roles(): Role[] {
    return this._role;
  }

  @Input()
  set showFor(role: Role[]) {
    this._role = role;
  }
  ngOnInit() {
    this.store$
      .pipe(
        select(getCurrentUser),
        distinctUntilChanged(),
        map(dto => new User(dto)),
        takeUntil(this.destroy$)
      )
      .subscribe(currentUser => {
        let show = false;
        if (currentUser && this.roles && this.roles.length > 0) {
          let temp = this.roles.map(role => currentUser.hasRole(role))
          show = temp.indexOf(true) != -1;
        }

        this.viewContainer.clear();
        if (show) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
