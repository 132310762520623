import { Pipe, PipeTransform } from "@angular/core";
import { SessionType } from "src/app/commons/models/session.model";

@Pipe({
  name: "sessionType",
})
export class SessionTypePipe implements PipeTransform {
  transform(value: SessionType): unknown {
    switch (value) {
      // case "local":
      //   return "Locale";
      case "send_app":
        return "Send App";
      case "socket":
        return "Socket";
      case "official":
        return "Official";
      default:
        return "";
    }
  }
}
