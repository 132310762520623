<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="message"></p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button *ngIf="!!confirmMessage" color="primary" (click)="dialogRef.close(true)">
    {{ confirmMessage }}
  </button>
  <button mat-raised-button *ngIf="!!cancelMessage" (click)="dialogRef.close(false)">
    {{ cancelMessage }}
  </button>
</div>