import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-hooks',
  templateUrl: './hooks.component.html',
  styleUrls: ['./hooks.component.scss']
})
export class HooksChangeComponent implements OnInit {

  hooksForm: UntypedFormGroup;

  user: User;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: UntypedFormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this._createForm();
    if (this.data && this.data.user) {
      this.user = new User(this.data.user);
    }
    this._initFormValues()
  }

  private _createForm() {
    this.hooksForm = this.fb.group({
      statusHook: [""],
      sentMessageHook: [""],
      recivedMessageHook: [""],
      failedMessageHook: [""],
    });
  }

  private _initFormValues() {
    if (this.user) {
      this.hooksForm.patchValue({
        statusHook: this.user.statusHook,
        sentMessageHook: this.user.sentMessageHook,
        recivedMessageHook: this.user.recivedMessageHook,
        failedMessageHook: this.user.failedMessageHook
      })
    }
  }

  private ngOnChanges() {
    if (this.hooksForm) {
      this.hooksForm.reset();
      this._initFormValues();
    }
  }

  save() {
    if (this.user) {
      const dto = this.user.toDTO();
      dto.status_hook = this.hooksForm.value.statusHook;
      dto.sent_message_hook = this.hooksForm.value.sentMessageHook;
      dto.recived_message_hook = this.hooksForm.value.recivedMessageHook;
      dto.failed_message_hook = this.hooksForm.value.failedMessageHook;
      this.store$.dispatch(UserActions.saveHooks({ user: dto }));
    }
  }

  close() {
    if (this.hooksForm.pristine) {
      this.store$.dispatch(UserActions.closeHooksDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }

  private doClose() {
    this.store$.dispatch(UserActions.closeHooksDialog());
  }
}








