import { createAction, props } from "@ngrx/store";
import { TemplatesMessageDTO, TemplatesMessageFilters } from "src/app/commons/models/templates-messages.model";

export const loadTemplatesMessage = createAction('[TemplatesMessage] Load templates message', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: TemplatesMessageFilters }>());
export const loadTemplatesMessageCompleted = createAction('[TemplatesMessage] Load templates message Completed', props<{ templatesMessage: TemplatesMessageDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: TemplatesMessageFilters }>());
export const loadTemplatesMessageFailed = createAction('[TemplatesMessage] Load templates message Failed', props<{ error: any }>());

export const loadTemplateMessage = createAction('[TemplateMessage] Load template message', props<{ id: number }>());
export const loadTemplateMessageCompleted = createAction('[TemplateMessage] Load template message Completed', props<{ templateMessage: TemplatesMessageDTO }>());
export const loadTemplateMessageFailed = createAction('[TemplateMessage] Load template message Failed', props<{ error: any }>());

export const changePage = createAction('[TemplatesMessage] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[TemplatesMessage] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[TemplatesMessage] Change filters', props<{ filters: TemplatesMessageFilters }>());

export const editTemplateMessage = createAction('[TemplateMessage] Edit template message', props<{ templateMessage: TemplatesMessageDTO }>());
export const selectTemplateMessage = createAction('[TemplateMessage] Select TemplateMessage', props<{ templateMessage: TemplatesMessageDTO }>());
export const setTemplateMessage = createAction('[TemplateMessage] Set TemplateMessage', props<{ templateMessage: TemplatesMessageDTO }>());

export const templateMessageDialogOpened = createAction('[TemplateMessage] Detail dialog opened', props<{ dialogId: string }>());
export const closeTemplateMessageDialog = createAction('[TemplateMessage] Close detail dialog');

export const sendTemplateMessage = createAction('[TemplateMessage] Send templateMessage', props<{ templateMessage: TemplatesMessageDTO }>());
export const sendTemplateMessageCompleted = createAction('[TemplateMessage] Send templateMessage completed', props<{ templateMessage: TemplatesMessageDTO }>());
export const sendTemplateMessageFailed = createAction('[TemplateMessage] Send templateMessage failed', props<{ error: any }>());
export const sendTemplateMessageCancelled = createAction('[TemplateMessage] Send templateMessage cancelled');

export const cloneTemplateMessage = createAction('[TemplateMessage] Clone templateMessage', props<{ templateMessage: TemplatesMessageDTO }>());
export const cloneTemplateMessageCompleted = createAction('[TemplateMessage] Clone TemplateMessage completed', props<{ templateMessage: TemplatesMessageDTO }>());
export const cloneTemplateMessageFailed = createAction('[TemplateMessage] Clone TemplateMessage failed', props<{ error: any }>());

export const saveTemplateMessage = createAction('[TemplateMessage] Save TemplateMessage', props<{ templateMessage: TemplatesMessageDTO }>());
export const saveTemplateMessageCompleted = createAction('[TemplateMessage] Save TemplateMessage completed', props<{ templateMessage: TemplatesMessageDTO }>());
export const saveTemplateMessageFailed = createAction('[TemplateMessage] Save TemplateMessage failed', props<{ error: any }>());

export const deleteTemplateMessage = createAction('[TemplateMessage] Delete TemplateMessage', props<{ templateMessage: TemplatesMessageDTO }>());
export const deleteTemplateMessageCompleted = createAction('[TemplateMessage] Delete TemplateMessage completed', props<{ templateMessage: TemplatesMessageDTO }>());
export const deleteTemplateMessageCancelled = createAction('[TemplateMessage] Delete TemplateMessage cancelled');
export const deleteTemplateMessageFailed = createAction('[TemplateMessage] Delete TemplateMessage failed', props<{ error: any }>());

export const retrieveTemplateMessage = createAction('[TemplateMessage] Retrieve TemplateMessage', props<{ templateMessage: TemplatesMessageDTO }>());
export const retrieveTemplateMessageCompleted = createAction('[TemplateMessage] Retrieve TemplateMessage completed', props<{ templateMessage: TemplatesMessageDTO }>());
export const retrieveTemplateMessageFailed = createAction('[TemplateMessage] Retrieve TemplateMessage failed', props<{ error: any }>());

export const backToClient = createAction('[TemplateMessage] Back to client', props<{ clientId: number }>());
