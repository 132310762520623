import { createSelector } from '@ngrx/store';

import { selectTemplatesMessage } from '../reducers';

import { TemplatesMessageState } from '../reducers/templates-messages.reducer';

export const getSessionId = createSelector(
  selectTemplatesMessage,
  (state: TemplatesMessageState) => state.sessionId || state.templateMessage?.session_id,
);

export const getTemplatesMessageTableState = createSelector(
  selectTemplatesMessage,
  (state: TemplatesMessageState) => { return { sessionId: state.sessionId, total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters } }
);

export const getAllTemplatesMessage = createSelector(
  selectTemplatesMessage,
  (state: TemplatesMessageState) => state.list
);

export const getTemplatesMessage = createSelector(
  getSessionId,
  getAllTemplatesMessage,
  (sessionId, templatesMessage) => {
    return templatesMessage?.filter(templatesMessage => templatesMessage.session_id === sessionId);
  }
)

export const getTotalTemplatesMessage = createSelector(
  getTemplatesMessageTableState,
  (tableState) => tableState.total
);

export const getTemplatesMessageCurrentPage = createSelector(
  getTemplatesMessageTableState,
  (tableState) => tableState.currentPage
);

export const getTemplatesMessagePerPage = createSelector(
  getTemplatesMessageTableState,
  (tableState) => tableState.perPage
);

export const getTemplatesMessageOrder = createSelector(
  getTemplatesMessageTableState,
  (tableState) => tableState.order
);

export const getTemplatesMessageDirection = createSelector(
  getTemplatesMessageTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getTemplatesMessageTableState,
  (tableState) => tableState.filters
);

export const getTemplatesMessageDialogId = createSelector(
  selectTemplatesMessage,
  (state: TemplatesMessageState) => {
    return state.dialogId;
  }
);

export const getTemplateMessage = createSelector(
  getTemplatesMessageTableState,
  (state: TemplatesMessageState) => state.templateMessage
);

export const getAllClients = createSelector(
  getTemplatesMessageTableState,
  (state: TemplatesMessageState) => state.clients
);
