import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Session, SessionDTO } from './session.model';

export type StatusType = "APPROVED" | "IN_APPEAL" | "REJECTED" | "PENDING" | "PENDING_DELETION" | "DELETED" | "DISABLED" | "PAUSED" | "LIMIT_EXCEEDED" | "NOT_SENT";

export type CategoryType = "MARKETING" | "UTILITY" | "AUTHENTICATION";

export type CategoryFileType = "IMAGE" | "VIDEO" | "DOCUMENT" | "OTHER";

export interface TemplatesMessageFilters {
  search?: string;
  status?: StatusType[];
  category?: CategoryType[];
  ids?: number[];
}

export const defaultFilters: TemplatesMessageFilters = {
  search: "",
  ids: []
}


export interface TemplatesMessageDTO extends BaseDTO {
  name: string;
  session_id: number;
  header: string;
  header_variables: { name: string, example_value: string, index: number }[];
  body: string;
  body_variables: { name: string, example_value: string, index: number }[];
  footer: string;
  button: string;
  header_type: string;
  image_header_url: string;
  video_header_url: string;
  document_header_url: string;
  category: CategoryType;
  status: StatusType;
  external_id: string;
  date_sent: Date;
  session: SessionDTO
}

export class TemplatesMessage extends Base {
  name: string;
  sessionId: number;
  header: string;
  headerVariables: { name: string, example_value: string, index: number }[];
  body: string;
  bodyVariables: { name: string, example_value: string, index: number }[];
  footer: string;
  button: string;
  headerType: string;
  imageHeader: [];
  imageHeaderUrl: string;
  videoHeader: [];
  videoHeaderUrl: string;
  documentHeader: [];
  documentHeaderUrl: string;
  category: CategoryType;
  externalId: string;
  dataSent: Date;
  status: StatusType;
  session: Session;

  constructor(source: TemplatesMessageDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.sessionId = source.session_id;
      this.status = source.status;
      this.body = source.body;
      this.bodyVariables = source.body_variables;
      this.footer = source.footer;
      this.button = source.button;
      this.headerType = source.header_type;
      this.imageHeaderUrl = source.image_header_url;
      this.videoHeaderUrl = source.video_header_url;
      this.documentHeaderUrl = source.document_header_url;

      this.category = source.category;
      this.externalId = source.external_id;
      this.dataSent = source.date_sent;
      this.header = source.header;
      this.headerVariables = source.header_variables;
      if (source.session) {
        this.session = new Session(source.session);
        this.addLoadedRelation('session');
      }
    }
  }

  toDTO(): TemplatesMessageDTO {
    let result: TemplatesMessageDTO = <TemplatesMessageDTO>super.toDTO();
    result.name = this.name;
    result.session_id = this.sessionId;
    result.status = this.status;
    result.body = this.body;
    result.body_variables = this.bodyVariables;
    result.footer = this.footer;
    result.button = this.button;
    result.header_type = this.headerType;
    result.image_header_url = this.imageHeaderUrl;
    if (this.imageHeader) {
      // result.image_header = this.imageHeader.toDTO();
      result.image_header_url = result.image_header_url;
    }
    result.video_header_url = this.videoHeaderUrl;
    if (this.videoHeader) {
      // result.video_header = this.videoHeader.toDTO();
      result.video_header_url = result.video_header_url;
    }
    result.document_header_url = this.documentHeaderUrl;
    if (this.documentHeader) {
      // result.document_header = this.documentHeader.toDTO();
      result.document_header_url = result.document_header_url;
    }

    result.category = this.category;
    result.external_id = this.externalId;
    result.date_sent = this.dataSent;
    result.header = this.header;
    result.header_variables = this.headerVariables;

    if (this.session) {
      result.session = this.session.toDTO();
    }

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: TemplatesMessage) {
    const formModel = formGroup.value;
    let templatesMessage: TemplatesMessage = new TemplatesMessage(null);

    if (original) {
      templatesMessage.id = original.id;
      templatesMessage.sessionId = original.sessionId;
    }
    templatesMessage.name = formModel.name;
    templatesMessage.status = formModel.status;
    templatesMessage.body = formModel.body;
    templatesMessage.bodyVariables = formModel.bodyVariables;
    templatesMessage.footer = formModel.footer;
    templatesMessage.button = formModel.button;
    templatesMessage.headerType = formModel.headerType;
    templatesMessage.imageHeaderUrl = formModel.imageHeader;
    templatesMessage.videoHeaderUrl = formModel.videoHeader;
    templatesMessage.documentHeaderUrl = formModel.documentHeader;
    templatesMessage.category = formModel.category;
    templatesMessage.externalId = formModel.externalId;
    templatesMessage.dataSent = formModel.dateSent;
    templatesMessage.header = formModel.header;
    templatesMessage.headerVariables = formModel.headerVariables;

    return templatesMessage;
  }
}
