import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Blacklist, BlacklistFilters } from '../../../commons/models/blacklist.model';
import { BIG_PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type BlacklistsColumn =
  | "id"
  | "user"
  | "phone"
  | "created_at"
  | "soft_block"
  | "actions";

@Component({
  selector: "blacklist-list",
  templateUrl: "./blacklist-list.component.html",
  styleUrls: ["./blacklist-list.component.scss"],
})
export class BlacklistListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  blacklists: Observable<Blacklist[]>;

  @Input()
  defaultFilters: BlacklistFilters;

  @Input()
  pageSize: number;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: BlacklistsColumn[] = [
    "user",
    "phone",
    "soft_block",
    "created_at",
    "actions",
  ];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Input()
  hideFilters = false;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BlacklistFilters> =
    new EventEmitter<BlacklistFilters>();

  @Output()
  onSelectBlacklist: EventEmitter<Blacklist> = new EventEmitter<Blacklist>();
  @Output()
  onAddBlacklist: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditBlacklist: EventEmitter<Blacklist> = new EventEmitter<Blacklist>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addBlacklist() {
    this.onAddBlacklist.emit();
  }

  editBlacklist(blacklist: Blacklist) {
    this.onEditBlacklist.emit(blacklist);
  }

  onFilter(filters: BlacklistFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
